// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GradientSecond_root__MReZJ {
  position: relative;
}

.GradientSecond_large__8SvmD {
  display: none;
}
@media (min-width: 1200px) {
  .GradientSecond_large__8SvmD {
    display: block;
    position: absolute;
    top: -50%;
    left: 35%;
    transform: translateX(-50%);
    width: 2733px;
    z-index: -1;
  }
}

.GradientSecond_small__9vbPu {
  display: none;
}
@media (min-width: 769px) {
  .GradientSecond_small__9vbPu {
    display: block;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    width: 1310px;
    z-index: -1;
  }
}
@media (min-width: 1200px) {
  .GradientSecond_small__9vbPu {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/mint/GradientSecond/GradientSecond.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;;AAIA;EACI,aAAA;AADJ;ACLI;EDKJ;IAIQ,cAAA;IACA,kBAAA;IACA,SAAA;IACA,SAAA;IACA,2BAAA;IACA,aAAA;IACA,WAAA;EAAN;AACF;;AAGA;EACI,aAAA;AAAJ;ACRI;EDOJ;IAIQ,cAAA;IACA,kBAAA;IACA,QAAA;IACA,SAAA;IACA,2BAAA;IACA,aAAA;IACA,WAAA;EACN;AACF;AC/BI;EDmBJ;IAcQ,aAAA;EAEN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.root {\n    position: relative;\n}\n\n.large {\n    display: none;\n\n    @include media-desktop() {\n        display: block;\n        position: absolute;\n        top: -50%;\n        left: 35%;\n        transform: translateX(-50%);\n        width: 2733px;\n        z-index: -1;\n    }\n}\n\n.small {\n    display: none;\n\n    @include media-tablet() {\n        display: block;\n        position: absolute;\n        top: 45%;\n        left: 50%;\n        transform: translateX(-50%);\n        width: 1310px;\n        z-index: -1;\n    }\n\n    @include media-desktop() {\n        display: none;\n    }\n}","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GradientSecond_root__MReZJ`,
	"large": `GradientSecond_large__8SvmD`,
	"small": `GradientSecond_small__9vbPu`
};
export default ___CSS_LOADER_EXPORT___;
