// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GradientFirst_root__LNgU\\+ {
  position: relative;
}

.GradientFirst_large__Utsyn {
  display: none;
}
@media (min-width: 769px) {
  .GradientFirst_large__Utsyn {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2525px;
    opacity: 0.35;
    z-index: -1;
  }
}

.GradientFirst_small__3ufe\\+ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1700px;
  opacity: 0.35;
  z-index: -1;
}
@media (min-width: 769px) {
  .GradientFirst_small__3ufe\\+ {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/mint/GradientFirst/GradientFirst.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;;AAIA;EACI,aAAA;AADJ;ACOI;EDPJ;IAIQ,cAAA;IACA,kBAAA;IACA,QAAA;IACA,SAAA;IACA,gCAAA;IACA,aAAA;IACA,aAAA;IACA,WAAA;EAAN;AACF;;AAGA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;AAAJ;ACfI;EDQJ;IAUQ,cAAA;EACN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.root {\n    position: relative;\n}\n\n.large {\n    display: none;\n\n    @include media-tablet() {\n        display: block;\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        width: 2525px;\n        opacity: 0.35;\n        z-index: -1;\n    }\n}\n\n.small {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 1700px;\n    opacity: 0.35;\n    z-index: -1;\n\n    @include media-tablet() {\n        display: block;\n    }\n}","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GradientFirst_root__LNgU+`,
	"large": `GradientFirst_large__Utsyn`,
	"small": `GradientFirst_small__3ufe+`
};
export default ___CSS_LOADER_EXPORT___;
