// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WebpImage_root__sOZOr {
  display: block;
}
.WebpImage_root__sOZOr img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/WebpImage/WebpImage.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AACR","sourcesContent":[".root {\n    display: block;\n\n    & img {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `WebpImage_root__sOZOr`
};
export default ___CSS_LOADER_EXPORT___;
