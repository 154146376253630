// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Marquee_row__A4Q\\+8 {
  height: 110px;
  overflow-y: hidden;
}
.Marquee_row__A4Q\\+8 + .Marquee_row__A4Q\\+8 {
  margin-top: -1px;
}
@media (min-width: 769px) {
  .Marquee_row__A4Q\\+8 {
    height: 200px;
  }
  .Marquee_row__A4Q\\+8 + .Marquee_row__A4Q\\+8 {
    margin-top: -2px;
  }
}

.Marquee_image__bMXbv {
  width: 110px;
  height: 110px;
}
@media (min-width: 769px) {
  .Marquee_image__bMXbv {
    width: 200px;
    height: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/main/Marquee/Marquee.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,kBAAA;AADJ;AAGI;EACI,gBAAA;AADR;ACOI;EDXJ;IASQ,aAAA;EADN;EAGM;IACI,gBAAA;EADV;AACF;;AAKA;EACI,YAAA;EACA,aAAA;AAFJ;ACNI;EDMJ;IAKQ,YAAA;IACA,aAAA;EADN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.row {\n    height: 110px;\n    overflow-y: hidden;\n\n    & + & {\n        margin-top: -1px;\n    }\n\n    @include media-tablet() {\n        height: 200px;\n\n        & + & {\n            margin-top: -2px;\n        }\n    }\n}\n\n.image {\n    width: 110px;\n    height: 110px;\n\n    @include media-tablet() {\n        width: 200px;\n        height: 200px;\n    }\n}","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Marquee_row__A4Q+8`,
	"image": `Marquee_image__bMXbv`
};
export default ___CSS_LOADER_EXPORT___;
