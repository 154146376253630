// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_root__2ueKP {
  margin-top: 60px;
}
@media (min-width: 769px) {
  .About_root__2ueKP {
    margin-top: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/main/About/About.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;ACWI;EDXJ;IAIQ,gBAAA;EAAN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.root {\n    margin-top: 60px;\n\n    @include media-tablet() {\n        margin-top: 80px;\n    }\n}","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `About_root__2ueKP`
};
export default ___CSS_LOADER_EXPORT___;
