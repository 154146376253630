// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TheGradientBackground_root__ZJiQN {
  position: relative;
  width: 100vw;
}

.TheGradientBackground_desktop__WVe3i {
  display: none;
  z-index: -1;
}
@media (min-width: 769px) {
  .TheGradientBackground_desktop__WVe3i {
    display: block;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1959px;
  }
}
@media (min-width: 1200px) {
  .TheGradientBackground_desktop__WVe3i {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2663px;
  }
}

.TheGradientBackground_mobile__xGdZ9 {
  position: absolute;
  width: 770px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media (min-width: 769px) {
  .TheGradientBackground_mobile__xGdZ9 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/main/ThirdGradientBackground/TheGradientBackground.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,YAAA;AADJ;;AAIA;EACI,aAAA;EACA,WAAA;AADJ;ACKI;EDNJ;IAKQ,cAAA;IACA,kBAAA;IACA,QAAA;IACA,SAAA;IACA,gCAAA;IACA,aAAA;EAAN;AACF;ACjBI;EDMJ;IAcQ,QAAA;IACA,SAAA;IACA,gCAAA;IACA,aAAA;EACN;AACF;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;AACJ;ACtBI;EDeJ;IASQ,aAAA;EAEN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.root {\n    position: relative;\n    width: 100vw;\n}\n\n.desktop {\n    display: none;\n    z-index: -1;\n\n    @include media-tablet() {\n        display: block;\n        position: absolute;\n        top: 65%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        width: 1959px;\n    }\n\n    @include media-desktop() {\n        top: 40%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        width: 2663px;\n    }\n}\n\n.mobile {\n    position: absolute;\n    width: 770px;\n    top: 55%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: -1;\n\n    @include media-tablet() {\n        display: none;\n    }\n}","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TheGradientBackground_root__ZJiQN`,
	"desktop": `TheGradientBackground_desktop__WVe3i`,
	"mobile": `TheGradientBackground_mobile__xGdZ9`
};
export default ___CSS_LOADER_EXPORT___;
