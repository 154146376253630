// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Goal_root__Wp9Rc {
  margin-top: 90px;
}
@media (min-width: 769px) {
  .Goal_root__Wp9Rc {
    margin-top: 140px;
  }
}
@media (min-width: 1200px) {
  .Goal_root__Wp9Rc {
    margin-top: 180px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/main/Goal/Goal.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;ACWI;EDXJ;IAIQ,iBAAA;EAAN;AACF;ACNI;EDCJ;IAQQ,iBAAA;EACN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.root {\n    margin-top: 90px;\n\n    @include media-tablet() {\n        margin-top: 140px;\n    }\n\n    @include media-desktop() {\n        margin-top: 180px;\n    }\n}\n","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Goal_root__Wp9Rc`
};
export default ___CSS_LOADER_EXPORT___;
