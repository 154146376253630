// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpacityImage_root__U9BcV {
  opacity: 0;
  transition: 0.3s ease;
  transition-property: opacity;
}

.OpacityImage_is-loaded__X0zo2 {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/OpacityImage/OpacityImage.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EAEA,qBAAA;EACA,4BAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ","sourcesContent":[".root {\n    opacity: 0;\n\n    transition: 0.3s ease;\n    transition-property: opacity;\n}\n\n.is-loaded {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `OpacityImage_root__U9BcV`,
	"is-loaded": `OpacityImage_is-loaded__X0zo2`
};
export default ___CSS_LOADER_EXPORT___;
