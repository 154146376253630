// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FirstGradientBackground_root__Poxsw {
  position: relative;
  overflow: hidden;
  width: 100vw;
  z-index: 3;
}

.FirstGradientBackground_desktop__rqlXK {
  display: none;
  z-index: -1;
}
@media (min-width: 769px) {
  .FirstGradientBackground_desktop__rqlXK {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2434px;
  }
}
@media (min-width: 1200px) {
  .FirstGradientBackground_desktop__rqlXK {
    left: calc(50% - 350px);
    transform: translateX(-50%);
    width: 2633px;
  }
}

.FirstGradientBackground_mobile__Hv-Vz {
  display: none;
  z-index: -1;
}
@media (max-width: 768px) {
  .FirstGradientBackground_mobile__Hv-Vz {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 769px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/main/FirstGradientBackground/FirstGradientBackground.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;AADJ;;AAIA;EACI,aAAA;EACA,WAAA;AADJ;ACGI;EDJJ;IAKQ,cAAA;IACA,kBAAA;IACA,MAAA;IACA,SAAA;IACA,2BAAA;IACA,aAAA;EAAN;AACF;ACnBI;EDQJ;IAcQ,uBAAA;IACA,2BAAA;IACA,aAAA;EACN;AACF;;AAEA;EACI,aAAA;EACA,WAAA;AACJ;ACDI;EDFJ;IAKQ,cAAA;IACA,kBAAA;IACA,MAAA;IACA,SAAA;IACA,2BAAA;IACA,YAAA;EAEN;AACF","sourcesContent":["@import \"src/app/styles/mixins.scss\";\n\n.root {\n    position: relative;\n    overflow: hidden;\n    width: 100vw;\n    z-index: 3;\n}\n\n.desktop {\n    display: none;\n    z-index: -1;\n\n    @include media-tablet() {\n        display: block;\n        position: absolute;\n        top: 0;\n        left: 50%;\n        transform: translateX(-50%);\n        width: 2434px;\n    }\n\n    @include media-desktop() {\n        left: calc(50% - 350px);\n        transform: translateX(-50%);\n        width: 2633px;\n    }\n}\n\n.mobile {\n    display: none;\n    z-index: -1;\n\n    @include media-max-mobile() {\n        display: block;\n        position: absolute;\n        top: 0;\n        left: 50%;\n        transform: translateX(-50%);\n        width: 769px;\n    }\n}","@mixin media-desktop {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n\n@mixin media-tablet {\n    @media (min-width: 769px) {\n        @content;\n    }\n}\n\n@mixin media-mobile {\n    @media (min-width: 320px) {\n        @content;\n    }\n}\n\n@mixin media-max-desktop {\n    @media (max-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin media-max-mobile {\n    @media (max-width: 768px) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FirstGradientBackground_root__Poxsw`,
	"desktop": `FirstGradientBackground_desktop__rqlXK`,
	"mobile": `FirstGradientBackground_mobile__Hv-Vz`
};
export default ___CSS_LOADER_EXPORT___;
